import { ModelType } from 'types/ModelType';
import { DependencyList, useEffect, useState } from 'react';
import { TokenTable } from 'types/backend/response/TokenTable';
import BackendQueryEngine from 'backend/BackendQueryEngine';

interface SampledDataPayload {
    model: ModelType;
    rowIds: number[];
}

export const useSampledData = (payloads: SampledDataPayload[], deps: DependencyList) => {
    const [tokenTable, setTokenTable] = useState<TokenTable[]>();

    useEffect(() => {
        let isRequestStillValid = true;

        const responsePromises = payloads.map(({ model, rowIds }) =>
            BackendQueryEngine.getTokens(model, {
                row_ids: rowIds,
            })
        );

        Promise.all(responsePromises).then((tokens) => {
            isRequestStillValid && setTokenTable(tokens);
        });

        return () => {
            isRequestStillValid = false;
        };
    }, deps);

    return tokenTable;
};

import React from 'react';
import ParameterContext from './ParameterContext';

interface Props {
    defaultNumPoints: number;
    defaultLayerID: number;
}

const ParameterContextProvider = ({
    children,
    defaultNumPoints = 10000,
    defaultLayerID = 1,
}: React.PropsWithChildren<Props>) => {
    const [numPoints, setNumPoints] = React.useState<number>(defaultNumPoints);
    const [gpt2LayerID, setGpt2LayerID] = React.useState<number>(defaultLayerID);
    const [bertLayerID, setBertLayerID] = React.useState<number>(defaultLayerID);

    return (
        <ParameterContext.Provider
            value={{
                numPoints,
                gpt2LayerID,
                bertLayerID,

                setNumPoints,
                setGpt2LayerID,
                setBertLayerID,
            }}
        >
            {children}
        </ParameterContext.Provider>
    );
};

export default ParameterContextProvider;

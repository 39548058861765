import React, { useContext, useState } from 'react';
import Section4SubFigure from './Section4Subfigure';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRevision } from '../../../hooks/useRevision';
import { Annotations } from 'plotly.js';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section4RightFigure: React.FunctionComponent<Props> = () => {
    const { bertLayerID } = useContext(ParameterContext);
    const [rev, updateRev] = useRevision();

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const annotations = [
        {
            x: 3.87,
            y: 1.43,
            z: -0.82,
            ax: 50,
            ay: 50,
            text: '<b>banks</b> has one large cluster in PCA',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const eyeUMAP = {
        x: -0.2085949927160975,
        y: -0.031638238802837296,
        z: -0.11398988027406938,
    };

    const annotationsUMAP = [
        {
            x: 12.613746643066406,
            y: 5.332480430603027,
            z: 7.30219841003418,
            ax: 30,
            ay: 70,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '<b>banks sense</b>: financial bank',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
        {
            x: 12.55788803100586,
            y: 6.044668197631836,
            z: 7.719347953796387,
            ax: -40,
            ay: 50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '<b>banks sense</b>: river bank',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    return (
        <>
            <Tabs onSelect={() => updateRev()} selectedIndex={selectedTab}>
                <TabList>
                    <Tab onClick={(d) => setSelectedTab(0)}>PCA</Tab>
                    <Tab onClick={(d) => setSelectedTab(1)}>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <Section4SubFigure
                        model={'BERT'}
                        projectionMethod={'PCA'}
                        revision={rev}
                        annotations={bertLayerID === 0 ? annotations : []}
                    />
                </TabPanel>
                <TabPanel>
                    <Section4SubFigure
                        model={'BERT'}
                        projectionMethod={'UMAP'}
                        revision={rev}
                        annotations={bertLayerID === 0 ? annotationsUMAP : []}
                        eye={eyeUMAP}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-right">
                <p>
                    We can see that in the <a onClick={(d) => setSelectedTab(0)}> PCA projection </a> of BERT, each word
                    has its own individual cloud. It seems that for higher layers, the contextualization increases, and
                    the contexts become more similar to each other. However, the clouds are still very fuzzy, and not so
                    condensed. If we switch to the <a onClick={(d) => setSelectedTab(1)}> UMAP projection </a>, we can
                    see a similar clustering for individual tokens as in GPT-2. The token &apos;bank&apos; is separated
                    into several clusters that represent the different senses of the word. However, the local clusters
                    seem to be a bit better differentiated, but this could also be an artifact of UMAP.
                </p>
            </div>
        </>
    );
};

export default Section4RightFigure;

import { ModelType } from 'types/ModelType';
import { Score } from 'types/Score';
import { useEffect, useState } from 'react';
import { ScoreTable } from 'types/backend/response/ScoreTable';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import BackendQueryEngine from 'backend/BackendQueryEngine';

export const useSampledScores = (
    maxSamples: number,
    model: ModelType,
    layer: number,
    score: Score,
    projectedEmbeddings: ProjectedEmbeddings
) => {
    const [scoreTable, setScore] = useState<ScoreTable>();
    let modelParent = model;
    if (model === 'GPT2LEFT' || model === 'GPT2RIGHT') {
        modelParent = 'GPT2';
    }
    // When token table is available, query projected embeddings
    useEffect(() => {
        let isRequestStillValid = true;

        if (projectedEmbeddings && projectedEmbeddings.x.length > 0) {
            BackendQueryEngine.getScores(modelParent, layer, score, {
                max_samples: maxSamples,
                row_ids: projectedEmbeddings.row_id,
            }).then((scores: ScoreTable) => {
                isRequestStillValid && setScore(scores);
            });
        }

        return () => {
            isRequestStillValid = false;
        };
    }, [score, layer, projectedEmbeddings]);

    return scoreTable;
};

import React, { useMemo, useState } from 'react';

export type SliderChangeHandler = (event: React.ChangeEvent<unknown>, value: number | number[]) => void;

export const useSlider = (initialValue?: number): [number, SliderChangeHandler] => {
    const [sliderValue, setSliderValue] = useState<number>(initialValue ?? 1);

    const handleSliderChange = useMemo(
        () => (event: React.ChangeEvent<unknown>, newValue: number | number[]) => {
            typeof newValue == 'number' && setSliderValue(newValue);
        },
        []
    );

    return [sliderValue, handleSliderChange];
};

import React, { useContext, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Section4SubFigure from 'App/Figures/Section4Figure/Section4Subfigure';
import { useRevision } from 'hooks/useRevision';
import { Annotations } from 'plotly.js';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section4Figure: React.FunctionComponent<Props> = () => {
    // Give the plotly figure a revision, so that it updates when tabs are changed, enforcing proper sizing
    const { gpt2LayerID } = useContext(ParameterContext);
    const [rev, updateRev] = useRevision();
    const [selectedTab, setSelectedTab] = useState<number>(2);

    const annotations = [
        {
            x: 7.313978452859388,
            y: 9.127470459779946,
            z: -3.211196847980076,
            ax: 30,
            ay: -190,
            text: '<b>banks</b> is placed along the manifold in PCA',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const eyeUMAP = {
        x: 0.026300893427389276,
        y: 0.14645753101082912,
        z: 0.5851201567126444,
    };

    const annotationsUMAP = [
        {
            x: 3.9165174961090092,
            y: 6.386744499206543,
            z: 17.014080047607422,
            ax: -80,
            ay: 60,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '<b>banks sense</b>: river bank',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
        // {
        //     x: -5.385331630706787,
        //     y: 1.068452954292297,
        //     z: 10.523820877075195,
        //     ax: 0,
        //     ay: -210,
        //     text: '',
        //     arrowhead: 1,
        //     xanchor: 'middle',
        //     yanchor: 'bottom',
        // },
        {
            x: 3.685709238052368,
            y: 5.712994575500488,
            z: 17.160627365112305,
            ax: 10,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '<b>banks sense</b>: financial bank',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    return (
        <>
            <Tabs onSelect={() => updateRev()} selectedIndex={selectedTab}>
                <TabList>
                    <Tab onClick={(d) => setSelectedTab(0)}>Left</Tab>
                    <Tab onClick={(d) => setSelectedTab(1)}>PCA</Tab>
                    <Tab onClick={(d) => setSelectedTab(2)}>Right</Tab>
                    <Tab onClick={(d) => setSelectedTab(3)}>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <Section4SubFigure model={'GPT2LEFT'} revision={rev} projectionMethod={'PCA'} />
                </TabPanel>
                <TabPanel>
                    <Section4SubFigure model={'GPT2'} revision={rev} projectionMethod={'PCA'} />
                </TabPanel>
                <TabPanel>
                    <Section4SubFigure
                        model={'GPT2RIGHT'}
                        revision={rev}
                        projectionMethod={'PCA'}
                        annotations={gpt2LayerID === 0 ? annotations : []}
                    />
                </TabPanel>
                <TabPanel id={'Fig4-umap'}>
                    <Section4SubFigure
                        model={'GPT2'}
                        revision={rev}
                        projectionMethod={'UMAP'}
                        eye={eyeUMAP}
                        annotations={gpt2LayerID === 0 ? annotationsUMAP : []}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-left">
                <p>
                    As can be clearly seen in the <a onClick={(d) => setSelectedTab(2)}> right PCA projection </a>, all
                    the words in GPT-2 are placed along a ring on the manifold. In lower layers, there is no
                    differentiation between polysemous words. As no good clustering inside of the embeddings of a single
                    token can be found in PCA, it makes sense to look at the at the{' '}
                    <a onClick={(d) => setSelectedTab(3)}> UMAP projection </a>. Here, we can see that the word
                    &apos;banks&apos; for example, is separated into multiple smaller clusters. If we zoom into
                    &apos;banks&apos;, you can see that actually the large cluster is two close clusters, that represent
                    two different senses of the word: the financial bank as well as the river bank.
                </p>
            </div>
        </>
    );
};

export default Section4Figure;

import React, { useEffect, useRef } from 'react';
import PlotlyPlot, { PlotParams } from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import _ from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import { useRevision } from 'hooks/useRevision';

// type StablePlotParams = Omit<PlotParams, 'revision'> & { revision: string };

const StablePlot: React.FunctionComponent<PlotParams> = (props: PlotParams) => {
    const { data, layout, config, revision: outerRevision, ...remainingProps } = props;

    /*
     * From https://github.com/plotly/react-plotly.js/:
     * Warning: for the time being, this component may mutate its layout and data props in response
     * to user input, going against React rules. This behaviour will change in the near future once
     * https://github.com/plotly/plotly.js/issues/2389 is completed.
     *
     * To resolve this, store a deep clone of data and layout in refs. When the props change their
     * values, update the ref's contents accordingly.
     */
    const dataRef = useRef<Plotly.Data[]>(_.cloneDeep(data));
    const layoutRef = useRef<Partial<Plotly.Layout>>(_.cloneDeep(layout));
    const configRef = useRef<Partial<Plotly.Config>>(_.cloneDeep(config as Plotly.Config));

    const [revision, updateRevision] = useRevision();

    useDeepCompareEffect(() => {
        // Create a deep copy of the data array and replace the former, Plotly-modified one
        dataRef.current = _.cloneDeep(data);
        // Trigger an update of the Plotly plot by creating a new revision
        updateRevision();
    }, [data]);

    useDeepCompareEffect(() => {
        // Create a deep copy of the new layout object and merge it with the former, Plotly-modified one
        // _.merge(layoutRef.current, _.cloneDeep(layout));
        layoutRef.current = _.cloneDeep(layout);
        // Trigger an update of the Plotly plot by creating a new revision
        updateRevision();
    }, [layout]);

    useEffect(() => {
        updateRevision();
    }, [outerRevision]);

    useEffect(() => {
        // Create a deep copy of the new layout object and merge it with the former, Plotly-modified one
        _.merge(configRef.current, _.cloneDeep(config));
        // Trigger an update of the Plotly plot by creating a new revision
        updateRevision();
    }, [config]);

    return (
        <PlotlyPlot
            revision={revision}
            data={dataRef.current}
            layout={layoutRef.current}
            config={configRef.current}
            useResizeHandler={true}
            style={{ width: '100%', height: '100%' }} //inherit
            {...remainingProps}
        />
    );
};

export default StablePlot;

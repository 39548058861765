export type Words =
    | 'friend'
    | 'spicy'
    | 'hot'
    | 'man'
    | 'woman'
    | 'letter'
    | 'banks'
    | 'material'
    | 'stress'
    | 'holiday'
    | 'facility'
    | 'circuit'
    | 'begin'
    | 'carry'
    | 'develop'
    | 'draw'
    | 'pull'
    | 'blind'
    | 'fit'
    | 'simple'
    | 'plants';

export const arrayWords: string[] = [
    'friend',
    'spicy',
    'hot',
    'man',
    'woman',
    'letter',
    'banks',
    'material',
    'stress',
    'holiday',
    'facility',
    'circuit',
    'begin',
    'carry',
    'develop',
    'draw',
    'pull',
    'blind',
    'fit',
    'simple',
    'plants',
];

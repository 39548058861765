import { HoverText } from 'types/HoverText';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { TokenTable } from 'types/backend/response/TokenTable';
import { Data, ScatterMarkerLine } from 'plotly.js';

export function bounds(array: Array<number>): [number, number] {
    const boundsRecord = array.reduce(
        ({ min, max }, item) => ({ min: Math.min(item, min), max: Math.max(item, max) }),
        {
            min: Infinity,
            max: -Infinity,
        }
    );

    return [boundsRecord.min, boundsRecord.max];
}

export const groupBy = function (xs: number[]) {
    return xs.reduce((rv, x, i) => {
        const a = (rv[x] = rv[x] || new Array<number>());
        a.push(i);
        return rv;
    }, new Array<number[]>());
};

//https://stackoverflow.com/questions/20798477/how-to-find-index-of-all-occurrences-of-element-in-array/20798567
export const indexOfAll = <T>(arr: T[], val: T): number[] =>
    arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), [] as number[]);

//https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

//https://stackoverflow.com/questions/51022834/react-jsx-make-substring-in-bold
export function BoldedText(text: string, shouldBeBold: string): HoverText {
    const capWord = capitalizeFirstLetter(shouldBeBold);

    const textArrayLeft = text.split(shouldBeBold);
    const textArrayLeftUpper = text.split(capWord);
    // let textArrayRight = [] as string[];
    let right = '';
    let left;
    if (textArrayLeft.length > 1) {
        right = textArrayLeft[1];
    }
    //If the word is the leftmost, textArrayRight is empty
    //If the word is the rightmost,
    // if (text.startsWith(shouldBeBold)) {
    //     console.log('start');
    // } else if (text.endsWith(shouldBeBold)) {
    //     console.log('end');
    // }
    // if (textArrayRight.length > 1) {
    //     right = textArrayRight[1];
    // }
    if (textArrayLeftUpper.length > textArrayLeft.length) {
        left = textArrayLeftUpper[0];
        if (textArrayLeftUpper.length > 1) {
            right = textArrayLeftUpper[1];
        } else {
            right = left.split(capitalizeFirstLetter(shouldBeBold))[1];
        }
        shouldBeBold = capitalizeFirstLetter(shouldBeBold);
    } else {
        left = textArrayLeft[0];
    }
    return {
        left: left,
        word: shouldBeBold,
        right: right,
    };
}

export function sliceProjection(embeddings: ProjectedEmbeddings, indexArray: number[]): ProjectedEmbeddings {
    const newProjection: ProjectedEmbeddings = {
        row_id: [],
        token_id: [],
        x: [],
        y: [],
        z: [],
    };
    const indexOfPosID = indexArray.map((d) => embeddings.row_id.findIndex((e) => e === d)) as number[];
    newProjection.row_id = indexArray.map((d, i) => embeddings.row_id[indexOfPosID[i]]);
    newProjection.token_id = indexArray.map((d, i) => embeddings.token_id[indexOfPosID[i]]);
    newProjection.x = indexArray.map((d, i) => embeddings.x[indexOfPosID[i]]);
    newProjection.y = indexArray.map((d, i) => embeddings.y[indexOfPosID[i]]);
    newProjection.z = indexArray.map((d, i) => embeddings.z[indexOfPosID[i]]);
    return newProjection;
}

export const createTracesPosTags = (
    tokenTable: TokenTable,
    projectedEmbeddings: ProjectedEmbeddings,
    currentTraceSelection: string[] = [],
    color: number[] = [],
    colorScale?: Array<[number, string]>
    // showColorscale = false,
    // dTick?: number[],
    // tickText?: string[]
): Data[] => {
    const pos_tag = tokenTable['pos_tag'];
    const tokens = tokenTable['token'];

    const unique_pos_tag = [...new Set(pos_tag)].sort();
    const unique_pos_tag_id = pos_tag.map((d) => unique_pos_tag.findIndex((e) => e === d));

    //Group by returns index
    const pos_tags_grouped_index = groupBy(unique_pos_tag_id);

    const pca_x_grouped = pos_tags_grouped_index.map((d) => d.map((e) => projectedEmbeddings.x[e]));
    const pca_y_grouped = pos_tags_grouped_index.map((d) => d.map((e) => projectedEmbeddings.y[e]));
    const pca_z_grouped = pos_tags_grouped_index.map((d) => d.map((e) => projectedEmbeddings.z[e]));
    const pos_tags_grouped = pos_tags_grouped_index.map((d) => d.map((e) => unique_pos_tag_id[e]));
    const tokens_grouped = pos_tags_grouped_index.map((d) => d.map((e) => tokens[e]));

    // sampledScoresGPT2Left['score']

    const traces_string = new Array<Data>();
    for (let i = 0; i < pos_tags_grouped.length; i++) {
        if (unique_pos_tag[i] !== 'Ŀ') {
            let colorInternal = Array(pos_tags_grouped[i].length).fill(i);
            if (color.length > 0) {
                //sampledScoresGPT2Left['score']
                colorInternal = pos_tags_grouped_index[i].map((d) => color[d] as number);
            }

            const marker = {
                coloraxis: 'coloraxis',
                cauto: false,
                cmin: 0,
                width: 1,
                color: color.length > 0 ? colorInternal : i,
                colorscale: color.length > 0 ? colorScale : undefined,
                // colorscale: 'Alphabet',
                // coloraxis: 'coloraxis',
                showscale: false,
                // colorbar:
                //     color.length > 0
                //         ? {
                //               x: 1.3,
                //               title: '',
                //               // autotick: false,
                //               tickmode: 'manual',
                //               tick0: 0,
                //               // dtick: dTick,
                //               tickvals: dTick,
                //               ticktext: tickText,
                //           }
                //         : undefined,
                size: 3,
                opacity: 0.5,
            } as ScatterMarkerLine;

            // const zipped = _.zip(colorInternal, tokens_grouped[i]);
            traces_string.push({
                visible:
                    currentTraceSelection.length === 0 ||
                    currentTraceSelection.some((d) => d === unique_pos_tag[i]) === true
                        ? true
                        : 'legendonly',
                name: unique_pos_tag[i],
                x: pca_x_grouped[i],
                y: pca_y_grouped[i],
                z: pca_z_grouped[i],
                type: 'scatter3d',
                mode: 'markers',
                customdata: colorInternal,
                text: tokens_grouped[i],
                hovertemplate: 'Token: %{text}<br>' + 'Score: %{customdata}',
                // 'PosTag: %{unique_pos_tag[i]}' +
                // hoverinfo:'text',
                hovertext: unique_pos_tag[i],
                marker: marker,
            });
        }
    }
    traces_string.push({
        visible: true,
        name: 'rest',
        x: projectedEmbeddings.x,
        y: projectedEmbeddings.y,
        z: projectedEmbeddings.z,
        type: 'scatter3d',
        mode: 'markers',
        showlegend: false,
        hoverinfo: 'skip',
        marker: {
            color: 'rgb(107,107,107)',
            size: 1,
            opacity: 0.3,
        },
    });
    return traces_string;
};

export const tableau20 = [
    '#1F77B4',
    '#98DF8A',
    '#8C564B',
    '#C7C7C7',
    '#AEC7E8',
    '#C49C94',
    '#D62728',
    '#BCBD22',
    '#FE7F0D',
    '#FF9895',
    '#E377C1',
    '#DBDB8D',
    '#FDBA78',
    '#9467BD',
    '#F7B6D2',
    '#17BECF',
    '#2BA02C',
    '#C5B0D5',
    '#7F7F7F',
    '#9edae5',
    '#9d6c34',
];

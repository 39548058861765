export interface ProjectedEmbeddings {
    row_id: number[];
    token_id: number[];
    x: number[];
    y: number[];
    z: number[];
}

export const DEFAULT_PROJECTED_EMBEDDINGS: ProjectedEmbeddings = {
    row_id: [],
    token_id: [],
    x: [],
    y: [],
    z: [],
};

// export const DEFAULT_PROJECTED_EMBEDDINGS_ARRAY: ProjectedEmbeddings[] = [
//     {
//         row_id: [],
//         token_id: [],
//         x: [],
//         y: [],
//         z: [],
//     },
// ];

import React from 'react';

interface IParameterContext {
    numPoints: number;
    gpt2LayerID: number;
    bertLayerID: number;

    setNumPoints: (numPoints: number) => void;
    setGpt2LayerID: (layerID: number) => void;
    setBertLayerID: (layerID: number) => void;
}

const defaultContext: IParameterContext = {
    numPoints: 0,
    gpt2LayerID: 1,
    bertLayerID: 1,

    setNumPoints: () => {
        // Initial value. Replaced by context provider.
    },
    setGpt2LayerID: () => {
        // Initial value. Replaced by context provider.
    },
    setBertLayerID: () => {
        // Initial value. Replaced by context provider.
    },
};

const ParameterContext = React.createContext(defaultContext);

export default ParameterContext;

export interface TokenTable {
    row_id: number[];
    token_id: number[];
    pos_id: number[];
    sample_pos: number[];
    pos_tag: string[];
    token: string[];
}

export const DEFAULT_TOKEN_TABLE: TokenTable = {
    row_id: [],
    token_id: [],
    pos_id: [],
    sample_pos: [],
    pos_tag: [],
    token: [],
};

export interface TokenRow {
    row_id: number;
    token_id: number;
    pos_id: number;
    sample_pos: number;
    pos_tag: string;
    token: string;
}

import { BACKEND_URL } from 'paths';
import { RowFilterPayload } from 'types/backend/request/RowFilterPayload';
import { JSONType } from 'types/JSONType';
import { ModelType } from 'types/ModelType';
import { ProjectionMethod } from 'types/ProjectionMethod';
import { TokenTable } from 'types/backend/response/TokenTable';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import {
    projectedEmbeddingsDecoder,
    scoreTableDecoder,
    sentenceTableDecoder,
    tokenTableDecoder,
} from 'backend/json-decoders';
import { Score } from 'types/Score';
import { ScoreTable } from 'types/backend/response/ScoreTable';
import { SentencesTable } from 'types/backend/response/SentencesTable';

enum ResponseType {
    JSON,
    BLOB,
    TEXT,
}

class BackendQueryEngine {
    private static async queryBackend(
        route: string,
        parameters: JSONType = {},
        responseType: ResponseType = ResponseType.JSON
    ): Promise<unknown> {
        const requestURL = `${BACKEND_URL}/${route}`;

        return fetch(requestURL, {
            body: JSON.stringify(parameters),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        }).then((response) => {
            if (responseType === ResponseType.JSON) return response.json();
            if (responseType === ResponseType.BLOB) return response.blob();
            if (responseType === ResponseType.TEXT) return response.text();

            return null;
        });
    }

    public static async getTokens(model: ModelType, filter?: RowFilterPayload): Promise<TokenTable> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`${model}/tokens`, filter);
        return await tokenTableDecoder.decodeToPromise(jsonResponse);
    }

    public static async getSentences(): Promise<SentencesTable> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(`sentences/sentences`);
        return await sentenceTableDecoder.decodeToPromise(jsonResponse);
    }

    public static async getProjectedEmbeddings(
        model: ModelType,
        layer: number,
        projectionMethod: ProjectionMethod,
        filter?: RowFilterPayload
    ): Promise<ProjectedEmbeddings> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
            `${model}/${layer}/embeddings/${projectionMethod}`,
            filter
        );

        return await projectedEmbeddingsDecoder.decodeToPromise(jsonResponse);
    }

    public static async getScores(
        model: ModelType,
        layer: number,
        score: Score,
        filter?: RowFilterPayload
    ): Promise<ScoreTable> {
        const jsonResponse: unknown = await BackendQueryEngine.queryBackend(
            `${model}/${layer}/scores/${score}`,
            filter
        );

        return await scoreTableDecoder.decodeToPromise(jsonResponse);
    }
}

export default BackendQueryEngine;

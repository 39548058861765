import { JsonDecoder } from 'ts.data.json';
import { TokenTable } from 'types/backend/response/TokenTable';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { ScoreTable } from 'types/backend/response/ScoreTable';
import { SentencesTable } from 'types/backend/response/SentencesTable';

const numberArrayDecoder = JsonDecoder.array<number>(JsonDecoder.number, 'number[]');
const stringArrayDecoder = JsonDecoder.array<string>(JsonDecoder.string, 'string[]');

// /* ******
//  * Date *
//  ****** */
// const dateDecoder: JsonDecoder.Decoder<Date> = new JsonDecoder.Decoder<Date>((json: unknown) => {
//     if (typeof json === 'string') {
//         const isValidDate = !isNaN(Date.parse(json));
//
//         if (isValidDate) {
//             return ok<Date>(new Date(json));
//         }
//     }
//
//     return err<Date>($JsonDecoderErrors.primitiveError(json, 'Date'));
// });

/* ************
 * TokenTable *
 ************ */
export const tokenTableDecoder = JsonDecoder.object<TokenTable>(
    {
        row_id: numberArrayDecoder,
        token_id: numberArrayDecoder,
        pos_id: numberArrayDecoder,
        sample_pos: numberArrayDecoder,
        pos_tag: stringArrayDecoder,
        token: stringArrayDecoder,
    },
    'TokenTable'
);

/* *********************
 * ProjectedEmbeddings *
 ********************* */
export const projectedEmbeddingsDecoder = JsonDecoder.object<ProjectedEmbeddings>(
    {
        row_id: numberArrayDecoder,
        token_id: numberArrayDecoder,
        x: numberArrayDecoder,
        y: numberArrayDecoder,
        z: numberArrayDecoder,
    },
    'ProjectedEmbeddings'
);

/* ************
 * ScoreTable *
 ************ */
export const scoreTableDecoder = JsonDecoder.object<ScoreTable>(
    {
        token_id: numberArrayDecoder,
        score: numberArrayDecoder,
    },
    'ScoreTable'
);

/* ************
 * SentenceTable*
 ************ */
export const sentenceTableDecoder = JsonDecoder.object<SentencesTable>(
    {
        row_id: numberArrayDecoder,
        sentence: stringArrayDecoder,
    },
    'SentenceTable'
);

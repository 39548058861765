import React from 'react';
import { Breadcrumbs, Link } from '@material-ui/core';
import styled from 'styled-components';

const StyledBreadcrumbs = styled(Breadcrumbs)`
    color: white;

    & * {
        color: white !important;
    }
`;

interface Props {
    anchorHierarchy: HTMLAnchorElement[];
}

const BreadCrumbs: React.FunctionComponent<Props> = ({ anchorHierarchy }: Props) => {
    return (
        <>
            <StyledBreadcrumbs aria-label="breadcrumb">
                {anchorHierarchy.map((anchorElement, idx) => {
                    const label = anchorElement?.getAttribute('data-label');

                    return (
                        <Link key={idx + anchorElement.id} href={anchorElement?.href}>
                            {anchorElement?.text + (label ? ' - ' + anchorElement?.getAttribute('data-label') : '')}
                        </Link>
                    );
                })}
            </StyledBreadcrumbs>
        </>
    );
};

export default BreadCrumbs;

import React from 'react';
import { Collapse, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

interface Props {}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(name: string, meaning: string, computation: string, formula: string) {
    return {
        name,
        meaning,
        computation,
        formula,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
    };
}

function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? 'up' : 'down'}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.meaning}</TableCell>
                <TableCell align="right">{row.computation}</TableCell>
                <TableCell align="right">{row.formula}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">{100}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const rows = [
    createData(
        'token-based-self-similarity',
        'How similar is the token to itself occurring in different contexts?',
        'average cosine similarity between the target token’s embedding to the same token’s embeddings occurring in different contexts',
        'Let t be the target token that appears in sentences {s_1, ...,  s_n}. Let f_l(s_i, t) be a function that returns the embedding for token t in sentence s_i.'
    ),
    createData(
        'token-based-intra-sentence-similarity',
        'How similar is the token to other tokens in the same context?',
        'average cosine similarity between the target token’s embedding to other tokens’ embeddings occurring in the same context',
        ''
    ),
    createData(
        'nearest-neighbor-cosine-similarity',
        'How similar is the token to its nearest neighbors in the high dimensional space?',
        'average cosine similarity between the target token’s embedding to its nearest neighbor embeddings',
        ''
    ),
    ///
    createData(
        'semantic-similarity',
        "Does the embedding encode the word's semantic meaning?",
        'average Wordnet word-based similarity between the target token and its nearest neighbor tokens',
        ''
    ),
    createData(
        'lexical-similarity',
        "Does the embedding encode the word's lexical form?",
        'average Levenshtein Distance-based similarity between the target token string to its nearest neighbor strings',
        ''
    ),
    createData(
        'same-POS-similarity',
        "Does the embedding encode the word's POS tag information?",
        'average number of times the target token’s POS tag is equal to its nearest neighbor POS tags (averaged)',
        ''
    ),
    createData(
        'same-POS-for-token-before-similarity',
        'Does the embedding encode the POS tag information for word n-grams?',
        'average number of times the token-before-the-target-token’s\n' +
            'POS tag is equal to the token-before-the-nearest-neighbor’s POS tags (averaged)\n',
        ''
    ),
    createData(
        'same-POS-for-token-after-similarity',
        'Does the embedding encode the POS tag information for word n-grams?',
        'average number of times the token-after-the-target-token’s\n' +
            'POS tag is equal to the token-after-the-nearest-neighbor’s POS tags (averaged)\n',
        ''
    ),
    createData(
        'number-of-characters-similarity',
        "Does the embedding encode the word's length?",
        "average similarity between the target token's character length and its nearest neighbor tokens character length",
        ''
    ),
    createData(
        'position-in-context-similarity',
        "Does the embedding encode the word's position in the context?",
        "average similarity between the target token's position and its nearest neighbor tokens position in the particular context\n" +
            '(0-1 corresponds to 0-10 tokens distance)\n',
        ''
    ),
    createData(
        'same-token-similarity\n',
        "Does the embedding encode the word's token (string)?",
        'average number of times the target token is equal to its nearest neighbor token',
        ''
    ),
];

const Section5Table: React.FunctionComponent<Props> = () => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Meaning</TableCell>
                            <TableCell align="right">Computation</TableCell>
                            <TableCell align="right">Formula</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default Section5Table;

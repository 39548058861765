import React from 'react';
import FigureContext from 'App/FigureContext';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { TokenTable } from 'types/backend/response/TokenTable';

interface Props {
    gpt2LeftProjections: ProjectedEmbeddings;
    gpt2RightProjections: ProjectedEmbeddings;
    gpt2Projections: ProjectedEmbeddings;
    bertProjections: ProjectedEmbeddings;
    gpt2LeftSampledData: TokenTable;
    gpt2RightSampledData: TokenTable;
    gpt2SampledData: TokenTable;
    bertSampledData: TokenTable;
    gpt2UmapProjections: ProjectedEmbeddings;
    bertUmapProjections: ProjectedEmbeddings;
}

const FigureContextProvider = ({
    gpt2LeftProjections,
    gpt2RightProjections,
    gpt2Projections,
    bertProjections,
    gpt2LeftSampledData,
    gpt2RightSampledData,
    gpt2SampledData,
    bertSampledData,
    gpt2UmapProjections,
    bertUmapProjections,
    children,
}: React.PropsWithChildren<Props>) => {
    return (
        <FigureContext.Provider
            value={{
                gpt2LeftProjections,
                gpt2RightProjections,
                gpt2Projections,
                bertProjections,
                gpt2LeftSampledData,
                gpt2RightSampledData,
                gpt2SampledData,
                bertSampledData,
                gpt2UmapProjections,
                bertUmapProjections,
            }}
        >
            {children}
        </FigureContext.Provider>
    );
};

export default FigureContextProvider;

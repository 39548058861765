import { Score } from './Score';

export interface Option {
    value: Score;
    label: string;
}

export const arrayScores: Option[] = [
    // { value: 'bothupperlower', label: 'bothupperlower' },
    //{ value: 'chars', label: 'chars' },
    { value: 'cosine', label: 'cosine-sim-to-nns' },
    { value: 'intrasent', label: 'token-based-intra-sent-sim' },
    //{ value: 'lexical', label: 'lexical' },
    // { value: 'partofcontext', label: 'partofcontext' },
    //{ value: 'position', label: 'position' },
    //{ value: 'samepos', label: 'samepos' },
    //{ value: 'sameposafter', label: 'sameposafter' },
    //{ value: 'sameposbefore', label: 'sameposbefore' },
    { value: 'sametoken', label: 'nns-are-same-tokens' },
    { value: 'selfsim', label: 'token-based-self-sim' },
    //{ value: 'semantic', label: 'semantic' },
];

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Icons from 'icons';
import { Buffer } from 'buffer';

const loadingIconString = ReactDOMServer.renderToString(<Icons.LoadingIcon width={32} height={32} />);
const loadingIconURIEncoded = Buffer.from(loadingIconString).toString('base64');
const loadingCursor = `url(data:image/svg+xml;base64,${loadingIconURIEncoded}) 32 32, auto`;

export const useLoadingCursor = () => {
    const bodyRef = React.useRef(document.body);

    const set = React.useCallback(() => {
        bodyRef.current.style.cursor = loadingCursor;
    }, []);

    const unset = React.useCallback(() => {
        bodyRef.current.style.cursor = 'default';
    }, []);

    return [set, unset];
};

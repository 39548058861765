import React from 'react';
import { DEFAULT_PROJECTED_EMBEDDINGS, ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { DEFAULT_TOKEN_TABLE, TokenTable } from 'types/backend/response/TokenTable';

interface IFigureContext {
    gpt2Projections: ProjectedEmbeddings;
    gpt2LeftProjections: ProjectedEmbeddings;
    gpt2RightProjections: ProjectedEmbeddings;
    bertProjections: ProjectedEmbeddings;
    gpt2SampledData: TokenTable;
    gpt2LeftSampledData: TokenTable;
    gpt2RightSampledData: TokenTable;
    bertSampledData: TokenTable;
    gpt2UmapProjections: ProjectedEmbeddings;
    bertUmapProjections: ProjectedEmbeddings;
}

const defaultContext: IFigureContext = {
    gpt2LeftProjections: DEFAULT_PROJECTED_EMBEDDINGS,
    gpt2RightProjections: DEFAULT_PROJECTED_EMBEDDINGS,
    gpt2Projections: DEFAULT_PROJECTED_EMBEDDINGS,
    bertProjections: DEFAULT_PROJECTED_EMBEDDINGS,
    gpt2LeftSampledData: DEFAULT_TOKEN_TABLE,
    gpt2RightSampledData: DEFAULT_TOKEN_TABLE,
    gpt2SampledData: DEFAULT_TOKEN_TABLE,
    bertSampledData: DEFAULT_TOKEN_TABLE,
    gpt2UmapProjections: DEFAULT_PROJECTED_EMBEDDINGS,
    bertUmapProjections: DEFAULT_PROJECTED_EMBEDDINGS,
};

const FigureContext = React.createContext(defaultContext);

export default FigureContext;

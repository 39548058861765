import React, { useContext } from 'react';
import chroma from 'chroma-js';
import { Annotations, ColorScale } from 'plotly.js';
import FigureContext from 'App/FigureContext';
import SubFigureContent from 'App/Figures/SubFigure/SubFigure';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRevision } from '../../../hooks/useRevision';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section2Figure: React.FunctionComponent<Props> = () => {
    const {
        gpt2RightProjections,
        gpt2LeftProjections,
        gpt2Projections,
        gpt2LeftSampledData,
        gpt2RightSampledData,
        gpt2SampledData,
        gpt2UmapProjections,
    } = useContext(FigureContext);

    const { gpt2LayerID } = useContext(ParameterContext);

    const [rev, updateRev] = useRevision();

    const colorsGPT2 = gpt2SampledData['pos_id'];
    const hoverTextGPT2 = gpt2SampledData['pos_id'].map((d) => {
        return d.toString();
    });

    const hoverTextGPT2Left = gpt2LeftSampledData['pos_id'].map((d) => {
        return d.toString();
    });

    const colorsGPT2Right = gpt2RightSampledData['pos_id'];
    const hoverTextGPT2Right = gpt2RightSampledData['pos_id'].map((d) => {
        return d.toString();
    });

    const colorscale = [] as [number, string][];
    const colorscaleLabels = [] as string[];

    //chroma.limits(data, 'l', 4);

    const colorsNormalized = colorsGPT2.map((d) => d / 260);
    const colors = chroma.scale('Viridis').domain([1, 0]).colors(11);
    // const steps = chroma.limits([1, 0.01], 'l', 10);
    const steps = chroma.limits(colorsNormalized, 'q', 10);
    // const colors = chroma.scale('interpolateTurbo').domain([1, 0]).colors(11).reverse();
    // const steps = chroma.limits([1, 0.01], 'l', 10);
    colorscale.push([0, colors[0]]);
    for (let i = 1; i < 11; i++) {
        const colorPoint = [steps[i], colors[i]] as [number, string];
        colorscale.push(colorPoint);
        colorscaleLabels.push(i.toString());
    }
    const colorscaleGPT2Left = [[0, colors[0]]] as ColorScale;

    const [currentAnnotations, setCurrentAnnotations] = React.useState<Annotations[]>([
        {
            x: 120,
            y: 0,
            z: 0,
            ax: -50,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'contains only position ID 0',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: 0,
            y: -5,
            z: 20,
            ax: -50,
            ay: 0,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'manifold with other positional IDs',
            arrowhead: 1,
            xanchor: 'right',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[]);

    function setFunctionWords() {
        setCurrentAnnotations([
            {
                x: 120,
                y: 0,
                z: 0,
                ax: -50,
                ay: -50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>contains only position ID 0</b>',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: 0,
                y: -5,
                z: 20,
                ax: -50,
                ay: 0,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'manifold with other positional IDs',
                arrowhead: 1,
                xanchor: 'right',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    function setContentWords() {
        setCurrentAnnotations([
            {
                x: 120,
                y: 0,
                z: 0,
                ax: -50,
                ay: -50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'contains only position ID 0',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: 0,
                y: -5,
                z: 20,
                ax: -50,
                ay: 0,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>manifold with other positional IDs</b>',
                arrowhead: 1,
                xanchor: 'right',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    const eyeUMAP = {
        x: 1.339185607200479,
        y: -1.0371155844402749,
        z: 0.3619021607813222,
    };

    const annotationsUMAP = [
        {
            x: 15,
            y: 10,
            z: 10,
            ax: -10,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '<b>The 0 cluster is loosely maintained</b>',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
        {
            x: 0.439770221710205,
            y: -5.220395565032959,
            z: 0.833963394165039,
            ax: 20,
            ay: 60,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'Some clusters retain the manifold structure',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    return (
        <>
            <Tabs defaultIndex={1} onSelect={() => updateRev()}>
                <TabList>
                    <Tab>Left</Tab>
                    <Tab>PCA</Tab>
                    <Tab>Right</Tab>
                    <Tab>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <SubFigureContent
                        embeddings={gpt2LeftProjections}
                        color={colors[0]}
                        hoverInfo={'text'}
                        hoverText={hoverTextGPT2Left}
                        colorScale={colorscaleGPT2Left}
                        revision={rev}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        embeddings={gpt2Projections}
                        color={colorsGPT2}
                        hoverInfo={'text'}
                        hoverText={hoverTextGPT2}
                        colorScale={colorscale}
                        revision={rev}
                        annotations={gpt2LayerID === 0 ? currentAnnotations : []}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        embeddings={gpt2RightProjections}
                        color={colorsGPT2Right}
                        hoverInfo={'text'}
                        hoverText={hoverTextGPT2Right}
                        colorScale={colorscale}
                        revision={rev}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        embeddings={gpt2UmapProjections}
                        color={colorsGPT2}
                        hoverInfo={'text'}
                        hoverText={hoverTextGPT2}
                        colorScale={colorscale}
                        revision={rev}
                        eye={eyeUMAP}
                        annotations={gpt2LayerID === 0 ? annotationsUMAP : []}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-left">
                <p>
                    As we saw previously, GPT-2 is strongly separated into two clusters for all layers but the last - if
                    we color the tokens by their position in the sentences, we can see that the{' '}
                    <a onClick={(d) => setFunctionWords()}>
                        {' '}
                        small round cluster contains all and only the tokens at position 0{' '}
                    </a>
                    . GPT-2 seems to place a large emphasis on this initial token. This makes sense, as the first token
                    is especially important for prediction of the rest of the sentence. It basically sets the groundwork
                    and context of the rest of the sentence. The{' '}
                    <a onClick={(d) => setContentWords()}>
                        other positions are placed along a Swiss roll manifold shape{' '}
                    </a>{' '}
                    - this also makes sense, as there is an order between the positions, however, the first and last
                    position should be different to each other. If we look through the layers, we can also observe that
                    this feature is preserved consistently. Overall, we can say that for GPT-2, position of a token is a
                    feature that is encoded very strongly in the embedding space.
                </p>
            </div>
        </>
    );
};

export default Section2Figure;

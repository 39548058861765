import { useMemo, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Option } from 'types/Option';

export type selectChangeHandler = (event: OnChangeValue<Option, false>) => void;

export const useSelect = (initialValue: Option): [Option, selectChangeHandler] => {
    const [selectedValue, setSelectedValue] = useState<Option>(initialValue);

    const handleSelectChange = useMemo(
        () => (option: OnChangeValue<Option, false>) => {
            if (option) {
                setSelectedValue(option);
            }
        },
        []
    );

    return [selectedValue, handleSelectChange];
};

import { Grid } from '@material-ui/core';
import Select from 'react-select';
import React, { useContext } from 'react';
import chroma from 'chroma-js';
import { arrayScores } from 'types/Option';
import FigureContext from 'App/FigureContext';
import SubFigureContent from 'App/Figures/SubFigure/SubFigure';
import { useSampledScores } from 'hooks/useSampledScores';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useSelect } from 'hooks/useSelect';
import ParameterContext from 'App/ParameterContext';
import { useRevision } from '../../../hooks/useRevision';
import { createTracesPosTags } from '../../../tools/helpers';
import _ from 'lodash';
import { Annotations } from 'plotly.js';

interface Props {}

const Section5Figure: React.FunctionComponent<Props> = () => {
    const {
        gpt2LeftSampledData,
        gpt2RightSampledData,
        gpt2RightProjections,
        gpt2LeftProjections,
        gpt2SampledData,
        gpt2Projections,
        gpt2UmapProjections,
    } = useContext(FigureContext);

    const { numPoints, gpt2LayerID } = useContext(ParameterContext);

    const [selectedScore, handleSelectedScoreChange] = useSelect(arrayScores[1]);

    const [rev, updateRev] = useRevision();

    const sampledScoresGPT2Left = useSampledScores(
        numPoints,
        'GPT2LEFT',
        gpt2LayerID,
        selectedScore.value,
        gpt2LeftProjections
    );
    const sampledScoresGPT2Right = useSampledScores(
        numPoints,
        'GPT2RIGHT',
        gpt2LayerID,
        selectedScore.value,
        gpt2RightProjections
    );
    const sampledScoresGPT2 = useSampledScores(numPoints, 'GPT2', gpt2LayerID, selectedScore.value, gpt2Projections);

    const annotationsIntraSentSimLayer0 = [
        {
            x: 123,
            y: 2,
            z: 2,
            ax: 30,
            ay: -30,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'tokens at the position 0 have' + '<br>' + ' the lowest similarity',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: -2,
            y: -12,
            z: 1,
            ax: 30,
            ay: -30,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'function words have a higher ' + '<br>' + ' similarity than content words',
            arrowhead: 1,
            xanchor: 'right',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const annotationsIntraSentSimLayer10 = [
        {
            x: 2860,
            y: 7,
            z: 4,
            ax: 30,
            ay: -30,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'tokens at the position 0 have' + '<br>' + 'the lowest similarity',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: -65,
            y: -90,
            z: -15,
            ax: -5,
            ay: -30,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'early tokens ' + '<br>' + ' (see section 2) ' + '<br>' + 'have the highest similarity',
            arrowhead: 1,
            xanchor: 'right',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const annotationsSelfSimLayer10 = [
        {
            x: -4,
            y: 2,
            z: 2,
            ax: -50,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'CCONJs, like other function words,' + '<br>' + 'have a low self-similarity',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    let annotations = [] as unknown as Annotations[];
    if (selectedScore.value === 'intrasent') {
        if (gpt2LayerID === 0) {
            annotations = annotationsIntraSentSimLayer0;
        } else if (gpt2LayerID === 10) {
            annotations = annotationsIntraSentSimLayer10;
        }
    } else if (selectedScore.value === 'selfsim') {
        if (gpt2LayerID === 10) {
            annotations = annotationsSelfSimLayer10;
        }
    }

    if (sampledScoresGPT2Left && sampledScoresGPT2Right && sampledScoresGPT2) {
        const roundedScoreLeft = sampledScoresGPT2Left['score'].map((d) => Math.round(d * 100) / 100);
        const roundedScoreRight = sampledScoresGPT2Right['score'].map((d) => Math.round(d * 100) / 100);
        const roundedScore = sampledScoresGPT2['score'].map((d) => Math.round(d * 100) / 100);

        let steps = _.uniq(chroma.limits(sampledScoresGPT2.score, 'q', 8).map((d) => Math.round(d * 100) / 100));
        steps = [...new Set(steps.map((d) => parseFloat(d.toFixed(1))))];
        const len = steps.length;
        const colors = chroma.scale('Viridis').domain([1, 0], len, 'hsl').colors(len);
        // const steps = chroma.limits([1, 0.01], 'l', 10);
        const colorscale = [] as Array<[number, string]>;
        const colorscaleLabels = [] as string[];
        const colorscaleTicks = [] as number[];
        // const step = 1 / 10;
        colorscaleTicks.push(0);
        colorscale.push([0, colors[0]]);
        colorscaleLabels.push('0');
        for (let i = 1; i < len; i++) {
            const colorPoint = [steps[i], colors[i]] as [number, string];
            if (i > 0 && steps[i] != steps[i - 1]) {
                colorscale.push(colorPoint);
                colorscaleLabels.push(steps[i].toString());
                colorscaleTicks.push(steps[i]);
            } else if (i === len - 1) {
                colorscale.push([steps[i], colors[colors.length - 1]] as [number, string]);
                colorscaleLabels.push(steps[i].toString());
                colorscaleTicks.push(steps[i]);
            }
        }
        if (colorscale[colorscale.length - 1][0] !== 1) {
            colorscaleTicks.push(1);
            colorscaleLabels.push('1');
            colorscale.push([1, colors[colors.length - 1]]);
        }
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        Scores:
                    </Grid>
                    <Grid item xs={7}>
                        <Select value={selectedScore} onChange={handleSelectedScoreChange} options={arrayScores} />
                    </Grid>
                </Grid>
                <Tabs defaultIndex={1} onSelect={() => updateRev()}>
                    <TabList>
                        <Tab>Left</Tab>
                        <Tab>PCA</Tab>
                        <Tab>Right</Tab>
                        <Tab>UMAP</Tab>
                    </TabList>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                gpt2LeftSampledData,
                                gpt2LeftProjections,
                                [],
                                roundedScoreLeft,
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                gpt2SampledData,
                                gpt2Projections,
                                [],
                                roundedScore,
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            // embeddings={gpt2Projections}
                            // color={sampledScoresGPT2['score']}
                            hoverInfo={'text'}
                            hoverText={gpt2SampledData['token']}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                            annotations={annotations}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                gpt2RightSampledData,
                                gpt2RightProjections,
                                [],
                                roundedScoreRight,
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            // embeddings={gpt2RightProjections}
                            // color={sampledScoresGPT2Right['score']}
                            hoverInfo={'text'}
                            hoverText={gpt2RightSampledData['token']}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                gpt2SampledData,
                                gpt2UmapProjections,
                                [],
                                roundedScore,
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            // embeddings={gpt2UmapProjections}
                            // color={sampledScoresGPT2['score']}
                            hoverInfo={'text'}
                            hoverText={gpt2SampledData['token']}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                        />
                    </TabPanel>
                </Tabs>
            </>
        );
    }
    return null;
};

export default Section5Figure;

import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { Data } from 'plotly.js';
import { useEffect, useState } from 'react';

export const useTracesRest = (projectedEmbeddings: ProjectedEmbeddings) => {
    const [tracesWords, setTracesWords] = useState<Data>({});

    useEffect(() => {
        if (projectedEmbeddings && projectedEmbeddings.x.length > 0) {
            //get all tokens from the token table
            setTracesWords({
                name: 'rest',
                x: projectedEmbeddings.x,
                y: projectedEmbeddings.y,
                z: projectedEmbeddings.z,
                type: 'scatter3d',
                mode: 'markers',
                showlegend: false,
                hoverinfo: 'skip',
                marker: {
                    color: 'rgb(107,107,107)',
                    size: 1,
                    opacity: 0.3,
                },
            });
        }
    }, [projectedEmbeddings]);

    return tracesWords;
};

import React, { useContext } from 'react';
import chroma from 'chroma-js';
import FigureContext from 'App/FigureContext';
import SubFigureContent from 'App/Figures/SubFigure/SubFigure';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRevision } from '../../../hooks/useRevision';
import { Annotations } from 'plotly.js';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section2RightFigure: React.FunctionComponent<Props> = () => {
    const { bertProjections, bertSampledData, bertUmapProjections } = useContext(FigureContext);

    const { bertLayerID } = useContext(ParameterContext);

    const [rev, updateRev] = useRevision();

    const colorsBert = bertSampledData['pos_id'];
    const hoverTextBert = bertSampledData['pos_id'].map((d) => {
        return d.toString();
    });

    const colorscale = [] as [number, string][];
    const colorscaleLabels = [] as string[];
    const colorsNormalized = colorsBert.map((d) => d / 260);
    const colors = chroma.scale('Viridis').domain([1, 0]).colors(11);
    // const steps = chroma.limits([1, 0.01], 'l', 10);
    const steps = chroma.limits(colorsNormalized, 'q', 10);
    steps[steps.length - 1] = 1;
    colorscale.push([0, colors[0]]);
    for (let i = 1; i < 11; i++) {
        const colorPoint = [steps[i], colors[i]] as [number, string];
        colorscale.push(colorPoint);
        colorscaleLabels.push(i.toString());
    }

    const annotationsUMAP = [
        {
            x: 3.02896761894226,
            y: 8.275378227233887,
            z: -7.09836483001709,
            ax: 50,
            ay: 50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'UMAP destroys the global structure',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const [currentAnnotations, setCurrentAnnotations] = React.useState<Annotations[]>([
        {
            x: -4.650941076908903,
            y: 3.745381240177916,
            z: -1.806619122428848,
            ax: -50,
            ay: 70,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'each cloud consists of unsorted posIDs',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[]);

    function setFunctionWords() {
        setCurrentAnnotations([
            {
                x: -4.650941076908903,
                y: 3.745381240177916,
                z: -1.806619122428848,
                ax: -50,
                ay: 70,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b> Each cloud consists of unsorted posIDs </b>',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    return (
        <>
            <Tabs defaultIndex={0} onSelect={() => updateRev()}>
                <TabList>
                    <Tab>PCA</Tab>
                    <Tab>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <SubFigureContent
                        embeddings={bertProjections}
                        color={colorsBert}
                        hoverInfo={'text'}
                        hoverText={hoverTextBert}
                        showColorbar={true}
                        titleColorbar={'absolute <br> position ID'}
                        colorScale={colorscale}
                        revision={rev}
                        annotations={bertLayerID === 0 ? currentAnnotations : []}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        embeddings={bertUmapProjections}
                        color={colorsBert}
                        hoverInfo={'text'}
                        hoverText={hoverTextBert}
                        showColorbar={true}
                        titleColorbar={'absolute  <br> position ID'}
                        colorScale={colorscale}
                        revision={rev}
                        annotations={bertLayerID === 0 ? annotationsUMAP : []}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-right">
                <p>
                    For BERT, position plays a different role, which is probably related to its masked word task and how
                    position is encoded in the model. On the first layer, we have{' '}
                    <a onClick={(d) => setFunctionWords()}> many small clusters </a> - actually, for each cluster the
                    positional encoding is kept separated somewhat. For each cloud, the individual position IDs seem to
                    be grouped together. But interestingly, they are not in a linear order, but shuffled. We will
                    investigate the meaning of the clusters in the next section!
                </p>
            </div>
        </>
    );
    return null;
};

export default Section2RightFigure;

import React, { useContext } from 'react';
import { createTracesPosTags } from 'tools/helpers';
import FigureContext from 'App/FigureContext';
import SubFigureContent from '../SubFigure/SubFigure';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRevision } from '../../../hooks/useRevision';
import { Annotations } from 'plotly.js';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section3RightFigure: React.FunctionComponent<Props> = () => {
    const { bertProjections, bertSampledData, bertUmapProjections } = useContext(FigureContext);
    const { bertLayerID } = useContext(ParameterContext);
    const [rev, updateRev] = useRevision();

    const [currentAnnotations, setCurrentAnnotations] = React.useState<Annotations[]>([
        {
            x: 3.87,
            y: 1.43,
            z: -0.82,
            ax: 50,
            ay: 50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'Content POS tags have more overlap',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: -4.78,
            y: 3.03,
            z: -1.42,
            ax: 30,
            ay: -80,
            az: -20,
            text: '',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
        {
            x: -4.78,
            y: 1.53,
            z: -1.42,
            ax: 50,
            ay: -70,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'Function POS tags can have' + '<br>' + 'multiple clouds',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[]);

    const eye = {
        x: 1,
        y: 1,
        z: 1,
    };

    const [currentTraceSelection, setcurrentTraceSelection] = React.useState<string[]>([]);

    function setFunctionWords() {
        setcurrentTraceSelection(['DET', 'PUNCT']);
        setCurrentAnnotations([
            {
                x: 3.87,
                y: 1.43,
                z: -0.82,
                ax: 50,
                ay: 50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'Content POS tags have more overlap',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: -4.78,
                y: 3.03,
                z: -1.42,
                ax: 30,
                ay: -80,
                az: -20,
                text: '',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
            {
                x: -4.78,
                y: 1.53,
                z: -1.42,
                ax: 50,
                ay: -70,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>Function POS tags can have' + '<br>' + 'multiple clouds</b>',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    function setContentWords() {
        setcurrentTraceSelection(['NOUN', 'VERB', 'ADJ']);
        setCurrentAnnotations([
            {
                x: 3.87,
                y: 1.43,
                z: -0.82,
                ax: 50,
                ay: 50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>Content POS tags have more overlap</b>',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: -4.78,
                y: 3.03,
                z: -1.42,
                ax: 30,
                ay: -80,
                az: -20,
                text: '',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
            {
                x: -4.78,
                y: 1.53,
                z: -1.42,
                ax: 50,
                ay: -70,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'Function POS tags can have <br> multiple clouds',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    return (
        <>
            <Tabs defaultIndex={0} onSelect={() => updateRev()}>
                <TabList>
                    <Tab>PCA</Tab>
                    <Tab>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(bertSampledData, bertProjections, currentTraceSelection)}
                        revision={rev}
                        eye={eye}
                        annotations={bertLayerID === 0 ? currentAnnotations : []}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(bertSampledData, bertUmapProjections, currentTraceSelection)}
                        revision={rev}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-right">
                <p>
                    In BERT, we observed multiple small clouds, that as we go up in the layers merge together. If we
                    color by POS tags, we can see that each of these clouds consists of a single POS tag. Some POS tags,
                    such as <a onClick={(d) => setFunctionWords()}>DET or PUNCT</a>, even have multiple small clouds,
                    that then merge together on upper layers. Similar to GPT-2,{' '}
                    <a onClick={(d) => setContentWords()}>NOUN, VERB, ADJ, and other content words</a> share the same
                    space in the embedding space in the first layer. However, in higher layers, such as layer 8, these
                    POS tags then start to better separate. At some point this starts to worsen again, and in the last
                    layer the overlap increases again, as other clusters emerge.
                </p>
            </div>
        </>
    );
};

export default Section3RightFigure;

import { Grid } from '@material-ui/core';
import Select from 'react-select';
import React, { useContext } from 'react';
import chroma from 'chroma-js';
import { arrayScores } from 'types/Option';
import FigureContext from 'App/FigureContext';
import SubFigureContent from 'App/Figures/SubFigure/SubFigure';
import { useSampledScores } from 'hooks/useSampledScores';
import { useSelect } from 'hooks/useSelect';
import ParameterContext from 'App/ParameterContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRevision } from '../../../hooks/useRevision';
import { createTracesPosTags } from '../../../tools/helpers';
import _ from 'lodash';
import { Annotations } from 'plotly.js';

interface Props {}

const Section5RightFigure: React.FunctionComponent<Props> = () => {
    const { bertSampledData, bertProjections, bertUmapProjections } = useContext(FigureContext);
    const { bertLayerID, numPoints } = useContext(ParameterContext);
    const [rev, updateRev] = useRevision();

    const [selectedScore, handleSelectedScoreChange] = useSelect(arrayScores[1]);

    const sampledScoresBert = useSampledScores(numPoints, 'BERT', bertLayerID, selectedScore.value, bertProjections);

    const annotationsIntraSentSimLayer0 = [
        {
            x: 4,
            y: 1,
            z: -1,
            ax: 5,
            ay: 60,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'content words have a lower ' + '<br>' + ' similarity than function words',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const annotationsIntraSentSimLayer10 = [
        {
            x: 6,
            y: -2,
            z: -4,
            ax: 50,
            ay: 50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'content words have a lower ' + '<br>' + ' similarity than function words',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: 11,
            y: 0.5,
            z: 1,
            ax: 30,
            ay: -70,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: '[SEP] tokens have a low similarity',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    const annotationsSelfSimLayer10 = [
        {
            x: -4,
            y: 2,
            z: 2,
            ax: 20,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'CCONJs have a higher self-similarity ' + '<br>' + ' than other function words',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: -2,
            y: 0,
            z: 1,
            ax: 40,
            ay: 90,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'DETs get more contextualized ' + '<br>' + ' than CCONJs',
            arrowhead: 1,
            xanchor: 'right',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[];

    let annotations = [] as unknown as Annotations[];
    if (selectedScore.value === 'intrasent') {
        if (bertLayerID === 0) {
            annotations = annotationsIntraSentSimLayer0;
        } else if (bertLayerID === 10) {
            annotations = annotationsIntraSentSimLayer10;
        }
    } else if (selectedScore.value === 'selfsim') {
        if (bertLayerID === 10) {
            annotations = annotationsSelfSimLayer10;
        }
    }

    if (sampledScoresBert) {
        const roundedScore = sampledScoresBert['score'].map((d) => Math.round(d * 100) / 100);

        let steps = _.uniq(chroma.limits(roundedScore, 'q', 10).map((d) => Math.round(d * 100) / 100));
        steps = [...new Set(steps.map((d) => parseFloat(d.toFixed(1))))];
        const len = steps.length;
        const colors = chroma.scale('Viridis').domain([1, 0], len, 'hsl').colors(len);
        // const steps = chroma.limits([1, 0.01], 'l', 10);

        const colorscale = [] as Array<[number, string]>;
        const colorscaleLabels = [] as string[];
        const colorscaleTicks = [] as number[];
        // const step = 1 / 10;
        colorscaleTicks.push(0);
        colorscale.push([0, colors[0]]);
        colorscaleLabels.push('0');
        for (let i = 1; i < len; i++) {
            const colorPoint = [steps[i], colors[i]] as [number, string];
            if (i > 0 && steps[i] != steps[i - 1]) {
                colorscale.push(colorPoint);
                colorscaleLabels.push(steps[i].toString());
                colorscaleTicks.push(steps[i]);
            } else if (i === len - 1) {
                colorscale.push([steps[i], colors[colors.length - 1]] as [number, string]);
                colorscaleLabels.push(steps[i].toString());
                colorscaleTicks.push(steps[i]);
            }
        }
        if (colorscale[colorscale.length - 1][0] !== 1) {
            colorscaleTicks.push(1);
            colorscaleLabels.push('1');
            colorscale.push([1, colors[colors.length - 1]]);
        }
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        Scores:
                    </Grid>
                    <Grid item xs={7}>
                        <Select value={selectedScore} onChange={handleSelectedScoreChange} options={arrayScores} />
                    </Grid>
                </Grid>
                <Tabs defaultIndex={0} onSelect={() => updateRev()}>
                    <TabList>
                        <Tab>PCA</Tab>
                        <Tab>UMAP</Tab>
                    </TabList>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                bertSampledData,
                                bertProjections,
                                [],
                                sampledScoresBert['score'],
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            // embeddings={bertProjections}
                            // color={sampledScoresBert['score']}
                            hoverInfo={'text'}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                            annotations={annotations}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SubFigureContent
                            data={createTracesPosTags(
                                bertSampledData,
                                bertUmapProjections,
                                [],
                                sampledScoresBert['score'],
                                colorscale
                                // true,
                                // colorscaleTicks
                            )}
                            // embeddings={bertUmapProjections}
                            // color={sampledScoresBert['score']}
                            hoverInfo={'text'}
                            hoverText={bertSampledData['token']}
                            showColorbar={false}
                            colorScale={colorscale}
                            manualColorScale={true}
                            tickValues={colorscaleTicks}
                            tickText={colorscaleLabels}
                            revision={rev}
                        />
                    </TabPanel>
                </Tabs>
            </>
        );
    }
    return null;
};

export default Section5RightFigure;

import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { useScroll } from 'react-use';
import BreadCrumbs from 'App/NavBar/BreadCrumbs';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const StyledAppBar = styled(AppBar)`
    background-color: hsl(200, 60%, 15%) !important;
    align-items: center;
`;

const isHTMLAnchorElement = (element?: Element): element is HTMLAnchorElement =>
    element !== undefined && element instanceof HTMLAnchorElement;

const useTopAccessor = () => {
    return useMemo(
        () =>
            (e: HTMLAnchorElement): number =>
                e.getBoundingClientRect().top,
        []
    );
};

const useTopAnchor = (anchorElements: HTMLAnchorElement[]) => {
    const [topAnchor, setTopAnchor] = useState<HTMLAnchorElement>();

    const scrollRef = React.useRef(document.getElementById('content-scroll'));
    const { x, y } = useScroll(scrollRef);

    const top = useTopAccessor();

    useEffect(() => {
        const sortedAnchors = anchorElements.filter((a) => top(a) > 0).sort((a, b) => top(a) - top(b));

        if (sortedAnchors.length > 0) {
            setTopAnchor(sortedAnchors[0]);
        }
    }, [x, y]);

    return topAnchor;
};

const useAnchorHierarchy = (anchorElements: HTMLAnchorElement[], separator = '.'): HTMLAnchorElement[] | undefined => {
    const topAnchor = useTopAnchor(anchorElements);

    if (topAnchor) {
        const parts = topAnchor.text.split(separator);

        // Make the intermediate strings, e.g., "1", "1.1", "1.1.1", ...
        const joinedParts = parts.reduce(
            (acc: string[], p: string) => [...acc, (acc.length > 0 ? acc.slice(-1) + separator : '') + p],
            []
        );

        return joinedParts.map((s) => anchorElements.find((a) => a.text === s)).filter(isHTMLAnchorElement);
    }
};

interface Props {}

const NavBar: React.FunctionComponent<Props> = (props: Props) => {
    const anchorElements: HTMLAnchorElement[] = useMemo(
        () => [...document.getElementsByClassName('marker')].filter(isHTMLAnchorElement),
        []
    );

    const HeaderElement = document.getElementById('header');

    const anchorHierarchy = useAnchorHierarchy(anchorElements);

    return (
        <>
            {HeaderElement &&
                ReactDOM.createPortal(
                    <StyledAppBar position="static">
                        <Toolbar>{anchorHierarchy && <BreadCrumbs anchorHierarchy={anchorHierarchy} />}</Toolbar>
                    </StyledAppBar>,
                    HeaderElement
                )}
        </>
    );
};

export default NavBar;

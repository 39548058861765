import React, { useContext } from 'react';
import FigureContext from 'App/FigureContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { createTracesPosTags } from 'tools/helpers';
import SubFigureContent from '../SubFigure/SubFigure';
import { useRevision } from '../../../hooks/useRevision';
import { Annotations } from 'plotly.js';
import ParameterContext from '../../ParameterContext';

interface Props {}

const Section3Figure: React.FunctionComponent<Props> = () => {
    const {
        gpt2RightProjections,
        gpt2LeftProjections,
        gpt2Projections,
        gpt2LeftSampledData,
        gpt2RightSampledData,
        gpt2SampledData,
        gpt2UmapProjections,
    } = useContext(FigureContext);

    const { gpt2LayerID } = useContext(ParameterContext);

    const [rev, updateRev] = useRevision();

    const [currentAnnotations, setCurrentAnnotations] = React.useState<Annotations[]>([
        {
            x: -7.82,
            y: -9.81,
            z: -2.83,
            ax: 40,
            ay: -50,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'Function POS tags are on a tight ring',
            arrowhead: 1,
            xanchor: 'bottom',
            yanchor: 'bottom',
        },
        {
            x: 13.9,
            y: 12.66,
            z: -6.29,
            ax: -40,
            ay: 60,
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            text: 'Content words occupy more rings and <br> have overlap with other POS tags',
            arrowhead: 1,
            xanchor: 'middle',
            yanchor: 'bottom',
        },
    ] as unknown as Annotations[]);

    // const annotations = [
    //     {
    //         x: -7.82,
    //         y: -9.81,
    //         z: -2.83,
    //         ax: 40,
    //         ay: -50,
    //         bgcolor: 'rgba(255, 255, 255, 0.9)',
    //         text: '<b>Content POS tags have more overlap</b>',
    //         arrowhead: 1,
    //         xanchor: 'bottom',
    //         yanchor: 'bottom',
    //     },
    //     {
    //         x: 13.9,
    //         y: 12.66,
    //         z: -6.29,
    //         ax: -40,
    //         ay: 60,
    //         bgcolor: 'rgba(255, 255, 255, 0.9)',
    //         text: 'Content words occupy more rings and <br> have overlap with other POS tags',
    //         arrowhead: 1,
    //         xanchor: 'middle',
    //         yanchor: 'bottom',
    //     },
    // ] as unknown as Annotations[];

    const eye = {
        x: 0.4,
        y: 0.5,
        z: 1.2,
    };

    const [currentTraceSelection, setcurrentTraceSelection] = React.useState<string[]>([]);

    function setFunctionWords() {
        setcurrentTraceSelection(['DET', 'PART', 'ADP', ' CCONJ']);
        setCurrentAnnotations([
            {
                x: -7.82,
                y: -9.81,
                z: -2.83,
                ax: 40,
                ay: -50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>Function POS tags are on a tight ring</b>',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: 13.9,
                y: 12.66,
                z: -6.29,
                ax: -40,
                ay: 60,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'Content words occupy more rings and <br> have overlap with other POS tags',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    function setContentWords() {
        setcurrentTraceSelection(['NOUN', 'VERB', 'ADJ']);
        setCurrentAnnotations([
            {
                x: -7.82,
                y: -9.81,
                z: -2.83,
                ax: 40,
                ay: -50,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: 'Function POS tags are on a tight ring',
                arrowhead: 1,
                xanchor: 'bottom',
                yanchor: 'bottom',
            },
            {
                x: 13.9,
                y: 12.66,
                z: -6.29,
                ax: -40,
                ay: 60,
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                text: '<b>Content words occupy more rings and <br> have overlap with other POS tags</b>',
                arrowhead: 1,
                xanchor: 'middle',
                yanchor: 'bottom',
            },
        ] as unknown as Annotations[]);
    }

    return (
        <>
            <Tabs defaultIndex={2} onSelect={() => updateRev()}>
                <TabList>
                    <Tab>Left</Tab>
                    <Tab>PCA</Tab>
                    <Tab>Right</Tab>
                    <Tab>UMAP</Tab>
                </TabList>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(gpt2LeftSampledData, gpt2LeftProjections, currentTraceSelection)}
                        annotations={gpt2LayerID === 0 ? currentAnnotations : []}
                        revision={rev}
                        eye={eye}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(gpt2SampledData, gpt2Projections, currentTraceSelection)}
                        revision={rev}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(gpt2RightSampledData, gpt2RightProjections, currentTraceSelection)}
                        revision={rev}
                        annotations={gpt2LayerID === 0 ? currentAnnotations : []}
                        eye={eye}
                    />
                </TabPanel>
                <TabPanel>
                    <SubFigureContent
                        data={createTracesPosTags(gpt2SampledData, gpt2UmapProjections, currentTraceSelection)}
                        revision={rev}
                    />
                </TabPanel>
            </Tabs>
            <div className="column-left">
                <p>
                    As observed, the roll manifold has several rings. If we sample enough datapoints, we can see that
                    some POS tags are very well distinguishable as rings of the roll. This is especially the case for
                    rings on the top of the swirl. Here, the POS tags are{' '}
                    <a onClick={(d) => setFunctionWords()}> mostly function words, such as DET, PART, ADP or CCONJ</a>.
                    POS tags that contain <a onClick={(d) => setContentWords()}> content, such as NOUN, VERB or ADJ</a>{' '}
                    are in the lower layers, and are distributed across multiple rings, and often on top of each other,
                    so they share the same space. We think that this means that GPT-2 can differentiate function words
                    on a much higher level than words that contain actual content. If we move up in the layers, we can
                    see that the separation of these content words improves a little bit, but overlap still remains.
                </p>
            </div>
        </>
    );
};

export default Section3Figure;

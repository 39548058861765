import { TokenTable } from 'types/backend/response/TokenTable';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { SentencesTable } from 'types/backend/response/SentencesTable';
import { HoverText } from 'types/HoverText';
import { Data } from 'plotly.js';
import { useEffect, useState } from 'react';
import { BoldedText } from 'tools/helpers';

export const useTracesTokens = (
    wordTokens: TokenTable[],
    embeddings: ProjectedEmbeddings[],
    wordList: string[],
    sentences: SentencesTable,
    layerID: number,
    numPoints: number,
    newElements: HoverText[][]
) => {
    const [tracesWords, setTracesWords] = useState<Data[]>([]);

    useEffect(() => {
        setTracesWords([]);
        // const tracesX = [] as number[];
        // const tracesY = [] as number[];
        // const tracesZ = [] as number[];
        // const tracesName = [] as string[];
        // const tracesCustomData = [] as string[];  // Optional; for additional hover data
        // const tracesID = [] as string[];
        // const traceswordList = [] as string[];

        if (wordList.length === wordTokens.length && wordList.length === embeddings.length) {
            for (let i = 0; i < wordList.length; i++) {
                const filteredTokenTableSampleID = wordTokens[i].sample_pos;
                const len = filteredTokenTableSampleID.length;
                const textSentence = filteredTokenTableSampleID.map((d) => sentences.sentence[d]);
                const separatedArray = textSentence.map((d) => BoldedText(d, wordList[i]));
                newElements.push(separatedArray);

                //Get all samples
                // tracesX.push(...embeddings[i].x);
                // tracesY.push(...embeddings[i].y);
                // tracesZ.push(...embeddings[i].z);
                // const names = [...Array(len).keys()].map((d) => wordList[i] + ' (' + len.toString() + ') ');
                // tracesName.push(...names);
                // tracesID.push(...Array(len).fill(wordList[i]));
                // tracesCustomData.push(...[...Array(filteredTokenTableSampleID.length).keys()]);
                // traceswordList.push(...Array(len).fill(wordList[i]));
                const data = {
                    name: wordList[i],
                    x: embeddings[i].x,
                    y: embeddings[i].y,
                    z: embeddings[i].z,
                    type: 'scatter3d',
                    mode: 'markers',
                    showlegend: true,
                    visible: wordList[i] === 'banks' || wordList[i] === 'Ġbanks' ? true : 'legendonly',
                    // customdata: tracesName, //textSentence,
                    text: Array(len).fill(wordList[i]),
                    hoverinfo: 'text',
                    hovertext: Array(len).fill(wordList[i]),
                    marker: {
                        color: i,
                        size: 3,
                        opacity: 0.5,
                    },
                    // transforms: [
                    //     {
                    //         type: 'groupby',
                    //         groups: tracesName,
                    //         // styles: [
                    //         //     { target: 'Moe', value: { marker: { color: 'blue' } } },
                    //         //     { target: 'Larry', value: { marker: { color: 'red' } } },
                    //         //     { target: 'Curly', value: { marker: { color: 'black' } } },
                    //         // ],
                    //     },
                    // ],
                } as Data;
                setTracesWords((tracesWords) => [...tracesWords, data]);
            }
        }
    }, [layerID, wordTokens, embeddings]);

    return tracesWords;
};

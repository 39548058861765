import { SentencesTable } from 'types/backend/response/SentencesTable';
import { useEffect, useState } from 'react';
import BackendQueryEngine from 'backend/BackendQueryEngine';

export const useSentences = (): SentencesTable | undefined => {
    const [sentences, setSentences] = useState<SentencesTable>();

    // Query token table
    useEffect(() => {
        BackendQueryEngine.getSentences().then((sentences) => {
            setSentences(sentences);
        });
    }, []);

    return sentences;
};

import { ModelType } from 'types/ModelType';
import { TokenTable } from 'types/backend/response/TokenTable';
import { useEffect, useState } from 'react';
import BackendQueryEngine from 'backend/BackendQueryEngine';

export const useTokens = (model: ModelType): TokenTable | undefined => {
    const [tokens, setTokens] = useState<TokenTable>();

    // Query token table
    useEffect(() => {
        BackendQueryEngine.getTokens(model).then((tokens) => {
            setTokens(tokens);
        });
    }, [model]);

    return tokens;
};

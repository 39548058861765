import React from 'react';
import { DEFAULT_TOKEN_TABLE, TokenTable } from 'types/backend/response/TokenTable';
import { DEFAULT_SENTENCE_TABLE, SentencesTable } from 'types/backend/response/SentencesTable';

interface ITokenContext {
    gpt2Tokens: TokenTable;
    bertTokens: TokenTable;
    sentences: SentencesTable;
}

const defaultContext: ITokenContext = {
    gpt2Tokens: DEFAULT_TOKEN_TABLE,
    bertTokens: DEFAULT_TOKEN_TABLE,
    sentences: DEFAULT_SENTENCE_TABLE,
};

const TokenContext = React.createContext(defaultContext);

export default TokenContext;

export interface HoverText {
    left: string;
    word: string;
    right: string;
}

export const DEFAULT_HOVER_TEXT: HoverText = {
    left: '',
    word: '',
    right: '',
};

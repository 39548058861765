import React from 'react';
import StablePlot from 'App/StablePlot/StablePlot';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { Annotations, ColorScale, Data, PlotMouseEvent, Point } from 'plotly.js';
import { tableau20 } from '../../../tools/helpers';
import * as Plotly from 'plotly.js';

declare module 'plotly.js' {
    interface Layout {
        coloraxis: Partial<{
            colorscale?: ColorScale | undefined;
            cauto?: boolean | undefined;
            cmax?: number | undefined;
            cmin?: number | undefined;
            autocolorscale?: boolean | undefined;
            reversescale?: boolean | undefined;
            showscale?: boolean | undefined;
            colorbar?: unknown;
        }>;
    }
}

interface Props {
    onHover?: (event: Readonly<PlotMouseEvent>) => void;
    revision?: number;
    data?: Data[];
    embeddings?: ProjectedEmbeddings;
    color?: string | number[];
    hoverInfo?: 'text' | 'x' | 'y' | 'z' | 'name' | 'none';
    hoverText?: string[];
    showColorbar?: boolean;
    titleColorbar?: string;
    tickValues?: number[];
    tickText?: string[];
    colorScale?: ColorScale;
    width?: number;
    height?: number;
    dtick?: number;
    opacity?: number;
    annotations?: Annotations[];
    eye?: Partial<Point>;
    manualColorScale?: boolean;
    tickvals?: number[];
    onLegendClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
    onLegendDoubleClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
}

const SubFigureContent: React.FunctionComponent<Props> = ({
    onHover,
    revision,
    data,
    embeddings,
    color,
    showColorbar = false,
    titleColorbar = '',
    colorScale = [],
    hoverInfo = 'none',
    hoverText = [],
    dtick = 20,
    opacity = 0.3,
    tickValues = [],
    tickText = [],
    annotations = [],
    eye = {
        x: 1,
        y: 1,
        z: 1,
    },
    onLegendClick,
    onLegendDoubleClick,
}: Props) => {
    let internalData = [] as Data[];
    if (data) {
        internalData = data;
    } else if (embeddings && colorScale) {
        internalData = [
            {
                x: embeddings.x,
                y: embeddings.y,
                z: embeddings.z,
                type: 'scatter3d',
                mode: 'markers',
                hoverinfo: hoverInfo,
                hovertext: hoverText,
                marker: {
                    cauto: false,
                    cmin: 0,
                    showscale: showColorbar,
                    color: color,
                    colorscale: colorScale,
                    size: 2,
                    opacity: opacity,
                    colorbar: {
                        title: titleColorbar,
                        // autotick: false,
                        tickmode: 'linear',
                        tick0: 0,
                        dtick: dtick,
                        // tickvals: tickValues,
                        // ticktext: tickText,
                    },
                },
            },
        ] as Data[];
    }

    return (
        <>
            <StablePlot
                revision={revision}
                data={internalData}
                layout={{
                    coloraxis: {
                        cauto: false,
                        cmin: 0,
                        cmax: 1,
                        colorscale: colorScale,
                        showscale: true,
                        colorbar: {
                            x: 1.3,
                            title: 'Hello',
                            // autotick: false,
                            tickmode: 'manual',
                            tick0: 0,
                            tickvals: tickValues,
                            ticktext: tickText,
                        },
                    },
                    colorway: tableau20, // Change default categorical colors
                    // width: 400,
                    // height: 400,
                    scene: {
                        annotations: annotations,
                        camera: {
                            eye: eye,
                        },
                        xaxis: {
                            title: '',
                            autorange: true,
                            showgrid: true,
                            zeroline: false,
                            showline: false,
                            ticks: '',
                            showticklabels: false,
                        },
                        yaxis: {
                            title: '',
                            autorange: true,
                            showgrid: true,
                            zeroline: false,
                            showline: false,
                            ticks: '',
                            showticklabels: false,
                        },
                        zaxis: {
                            title: '',
                            autorange: true,
                            showgrid: true,
                            zeroline: false,
                            showline: false,
                            ticks: '',
                            showticklabels: false,
                        },
                        aspectmode: 'manual',
                        aspectratio: { x: 1, y: 1, z: 1 },
                    },
                    margin: {
                        l: 10,
                        r: 10,
                        b: 0,
                        t: 0,
                        pad: 0,
                    },
                    autosize: true,
                }}
                config={{ responsive: true }}
                onHover={onHover ? onHover : undefined}
                onLegendClick={onLegendClick ? onLegendClick : undefined}
                onLegendDoubleClick={onLegendDoubleClick ? onLegendDoubleClick : undefined}
            />
        </>
    );
};

export default SubFigureContent;

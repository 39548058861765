import './wdyr';

// Import global libraries
import 'distill-template';
import 'katex';

// Import local libraries
import React from 'react';

// Import our components
import App from 'App/App';

import './index.css';

// Import global styles
import 'react-tabs/style/react-tabs.css';
import { createRoot } from 'react-dom/client';

const domNode = document.getElementById('react-app');

if (domNode) {
    const root = createRoot(domNode);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

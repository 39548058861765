import React, { PropsWithChildren, useContext } from 'react';
import ReactDOM from 'react-dom';
import Section2Figure from 'App/Figures/Section2Figure/Section2Figure';
import Section2RightFigure from 'App/Figures/Section2Figure/Section2RightFigure';
import Section3Figure from 'App/Figures/Section3Figure/Section3Figure';
import Section3RightFigure from 'App/Figures/Section3Figure/Section3RightFigure';
import Section5Figure from 'App/Figures/Section5Figure/Section5Figure';
import Section5RightFigure from 'App/Figures/Section5Figure/Section5RightFigure';
import ParameterPanel from 'App/ParameterPanel/ParameterPanel';
import TokenContextProvider from 'App/TokenContextProvider';
import { useSampledEmbeddings } from 'hooks/useSampledEmbeddings';
import { useTokens } from 'hooks/useTokens';
import { useSentences } from 'hooks/useSentences';
import ParameterContextProvider from 'App/ParameterContextProvider';
import ParameterContext from 'App/ParameterContext';
import { ProjectedEmbeddings } from 'types/backend/response/ProjectedEmbeddings';
import { useSampledData } from 'hooks/useSampledData';
import FigureContextProvider from 'App/FigureContextProvider';
import Section4Figure from 'App/Figures/Section4Figure/Section4Figure';
import Section4RightFigure from 'App/Figures/Section4Figure/Section4RightFigure';
import Section5Table from 'App/Tables/Figure5Table/Section5Table';
import NavBar from 'App/NavBar/NavBar';

const App: React.FunctionComponent = () => {
    return (
        <>
            <NavBar />
            <TokenContextWrapper />
        </>
    );
};

const TokenContextWrapper: React.FunctionComponent = () => {
    // console.log('TokenContextWrapper.render()');

    const gpt2Tokens = useTokens('GPT2');
    const bertTokens = useTokens('BERT');
    const sentences = useSentences();

    return (
        <>
            {gpt2Tokens && bertTokens && sentences && (
                <TokenContextProvider gpt2Tokens={gpt2Tokens} bertTokens={bertTokens} sentences={sentences}>
                    <ParameterContextWrapper />
                </TokenContextProvider>
            )}
        </>
    );
};

const ParameterContextWrapper: React.FunctionComponent = () => {
    // console.log('ParameterContextWrapper.render()');

    return (
        <>
            <ParameterContextProvider defaultNumPoints={5000} defaultLayerID={0}>
                <ParameterPanel />
                <ProjectionsWrapper />
            </ParameterContextProvider>
        </>
    );
};

const ProjectionsWrapper: React.FunctionComponent = () => {
    // console.log('ProjectionsWrapper.render()');

    const { numPoints, gpt2LayerID, bertLayerID } = useContext(ParameterContext);

    const projections = useSampledEmbeddings(
        [
            { maxSamples: numPoints, model: 'GPT2LEFT', layer: gpt2LayerID, projectionMethod: 'PCA' },
            { maxSamples: numPoints, model: 'GPT2RIGHT', layer: gpt2LayerID, projectionMethod: 'PCA' },
            { maxSamples: numPoints, model: 'GPT2', layer: gpt2LayerID, projectionMethod: 'PCA' },
            { maxSamples: numPoints, model: 'BERT', layer: bertLayerID, projectionMethod: 'PCA' },
            { maxSamples: numPoints, model: 'GPT2', layer: gpt2LayerID, projectionMethod: 'UMAP' },
            { maxSamples: numPoints, model: 'BERT', layer: bertLayerID, projectionMethod: 'UMAP' },
        ],
        [numPoints, gpt2LayerID, bertLayerID]
    );

    return (
        <>
            {projections && (
                <FigureContextWrapper
                    gpt2LeftProjections={projections[0]}
                    gpt2RightProjections={projections[1]}
                    gpt2Projections={projections[2]}
                    bertProjections={projections[3]}
                    gpt2UmapProjections={projections[4]}
                    bertUmapProjections={projections[5]}
                >
                    <App />
                </FigureContextWrapper>
            )}
        </>
    );
};

interface ProjectionsProps {
    gpt2LeftProjections: ProjectedEmbeddings;
    gpt2RightProjections: ProjectedEmbeddings;
    gpt2Projections: ProjectedEmbeddings;
    bertProjections: ProjectedEmbeddings;
    gpt2UmapProjections: ProjectedEmbeddings;
    bertUmapProjections: ProjectedEmbeddings;
}

const FigureContextWrapper: React.FunctionComponent<PropsWithChildren<ProjectionsProps>> = ({
    gpt2LeftProjections,
    gpt2RightProjections,
    gpt2Projections,
    bertProjections,
    gpt2UmapProjections,
    bertUmapProjections,
}: PropsWithChildren<ProjectionsProps>) => {
    const sampledData = useSampledData(
        [
            { model: 'GPT2', rowIds: gpt2LeftProjections.row_id },
            { model: 'GPT2', rowIds: gpt2RightProjections.row_id },
            { model: 'GPT2', rowIds: gpt2Projections.row_id },
            { model: 'BERT', rowIds: bertProjections.row_id },
        ],
        [gpt2LeftProjections, gpt2RightProjections, gpt2Projections, bertProjections, gpt2UmapProjections]
    );

    return (
        <>
            {sampledData && (
                <FigureContextProvider
                    gpt2LeftProjections={gpt2LeftProjections}
                    gpt2RightProjections={gpt2RightProjections}
                    gpt2Projections={gpt2Projections}
                    bertProjections={bertProjections}
                    gpt2LeftSampledData={sampledData[0]}
                    gpt2RightSampledData={sampledData[1]}
                    gpt2SampledData={sampledData[2]}
                    bertSampledData={sampledData[3]}
                    gpt2UmapProjections={gpt2UmapProjections}
                    bertUmapProjections={bertUmapProjections}
                >
                    <FigureInjector />
                </FigureContextProvider>
            )}
        </>
    );
};

const FigureInjector: React.FunctionComponent = () => {
    console.log('App.render()');

    const Section2FigureElement = document.getElementById('section-2-figure');
    const Section2RightFigureElement = document.getElementById('section-2-right-figure');
    const Section3FigureElement = document.getElementById('section-3-figure');
    const Section3RightFigureElement = document.getElementById('section-3-right-figure');
    const Section4FigureElement = document.getElementById('section-4-figure');
    const Section4RightFigureElement = document.getElementById('section-4-right-figure');
    const Section5FigureElement = document.getElementById('section-5-figure');
    const Section5RightFigureElement = document.getElementById('section-5-right-figure');
    const Section5TableElement = document.getElementById('section-5-table');

    return (
        <>
            {Section2FigureElement && ReactDOM.createPortal(<Section2Figure />, Section2FigureElement)}
            {Section2RightFigureElement && ReactDOM.createPortal(<Section2RightFigure />, Section2RightFigureElement)}
            {Section3FigureElement && ReactDOM.createPortal(<Section3Figure />, Section3FigureElement)}
            {Section3RightFigureElement && ReactDOM.createPortal(<Section3RightFigure />, Section3RightFigureElement)}
            {Section4FigureElement && ReactDOM.createPortal(<Section4Figure />, Section4FigureElement)}
            {Section4RightFigureElement && ReactDOM.createPortal(<Section4RightFigure />, Section4RightFigureElement)}
            {Section5FigureElement && ReactDOM.createPortal(<Section5Figure />, Section5FigureElement)}
            {Section5RightFigureElement && ReactDOM.createPortal(<Section5RightFigure />, Section5RightFigureElement)}
            {Section5TableElement && ReactDOM.createPortal(<Section5Table />, Section5TableElement)}
        </>
    );
};

export default App;

import React from 'react';
import { TokenTable } from 'types/backend/response/TokenTable';
import TokenContext from './TokenContext';
import { SentencesTable } from 'types/backend/response/SentencesTable';

interface Props {
    gpt2Tokens: TokenTable;
    bertTokens: TokenTable;
    sentences: SentencesTable;
}

const TokenContextProvider = ({ children, gpt2Tokens, bertTokens, sentences }: React.PropsWithChildren<Props>) => {
    return (
        <TokenContext.Provider
            value={{
                gpt2Tokens,
                bertTokens,
                sentences,
            }}
        >
            {children}
        </TokenContext.Provider>
    );
};

export default TokenContextProvider;

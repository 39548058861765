import React, { MouseEventHandler, useCallback, useContext, useState } from 'react';
import { Card, CardContent, Grid, Slider } from '@material-ui/core';
import styled from 'styled-components';
import { useSlider } from 'hooks/useSlider';
import ParameterContext from 'App/ParameterContext';

const Container = styled.div<{ $collapsed: boolean }>`
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    max-height: ${({ $collapsed }) => ($collapsed ? '24px' : 'unset')};
`;

const CollapseButton = styled.div<{ $collapsed: boolean }>`
    color: gray;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &:before {
        top: 0;
        position: absolute;
        font-size: 20px;
        content: '${({ $collapsed }) => ($collapsed ? '⌃' : '⌄')}';
        margin-top: ${({ $collapsed }) => ($collapsed ? '2px' : '-8px')};
    }
`;

const StyledCard = styled(Card)`
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
`;

const SettingsTable = styled.div`
    width: 100%;
    display: table;
`;

const SettingsTableRow = styled.div`
    width: 100%;
    display: table-row;
`;

const SettingsTableCell = styled.div`
    width: 100%;
    padding: 5px 20px;
    display: table-cell;
`;

const SettingsTableCellFlexWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 20px;
`;

const ValueContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 0;
`;

const CenterSliderGridCell = styled(Grid)`
    @media only screen and (min-width: 960px) {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
`;

const useCollapse = (): [boolean, MouseEventHandler<HTMLDivElement>] => {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const onCollapseExpandHandler = useCallback<MouseEventHandler<HTMLDivElement>>(
        () => setCollapsed((prevState) => !prevState),
        []
    );

    return [collapsed, onCollapseExpandHandler];
};

interface Props {}

const ParameterPanel: React.FunctionComponent<Props> = () => {
    const { numPoints, setNumPoints, setGpt2LayerID, setBertLayerID } = useContext(ParameterContext);

    const [numPointsSliderValue, handleNumPointsSliderValueChange] = useSlider(numPoints);

    const [gpt2LayerId, handleGpt2LayerIdChange] = useSlider(0);
    const [bertLayerID, handleBertLayerIdChange] = useSlider(0);

    const [collapsed, onCollapseExpandHandler] = useCollapse();

    return (
        <>
            <Container $collapsed={collapsed}>
                <StyledCard variant="outlined">
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4}>
                                <SettingsTable>
                                    <SettingsTableRow>
                                        <SettingsTableCell>
                                            Layer ID <b>GPT-2</b>:
                                        </SettingsTableCell>
                                    </SettingsTableRow>
                                    <SettingsTableRow>
                                        <SettingsTableCell>
                                            <SettingsTableCellFlexWrapper>
                                                <SliderContainer>
                                                    <Slider
                                                        min={0}
                                                        step={1}
                                                        max={11}
                                                        value={gpt2LayerId}
                                                        onChange={handleGpt2LayerIdChange}
                                                        onChangeCommitted={() => setGpt2LayerID(gpt2LayerId)}
                                                    />
                                                </SliderContainer>
                                                <ValueContainer>{gpt2LayerId + 1}</ValueContainer>
                                            </SettingsTableCellFlexWrapper>
                                        </SettingsTableCell>
                                    </SettingsTableRow>
                                </SettingsTable>
                            </Grid>
                            <CenterSliderGridCell item xs={12} sm={12} md={4}>
                                <SettingsTable>
                                    <SettingsTableRow>
                                        <SettingsTableCell>Maximum Number of Points:</SettingsTableCell>
                                    </SettingsTableRow>
                                    <SettingsTableRow>
                                        <SettingsTableCell>
                                            <SettingsTableCellFlexWrapper>
                                                <SliderContainer>
                                                    <Slider
                                                        min={1000}
                                                        step={1000}
                                                        max={50000}
                                                        value={numPointsSliderValue}
                                                        onChange={handleNumPointsSliderValueChange}
                                                        onChangeCommitted={() => setNumPoints(numPointsSliderValue)}
                                                    />
                                                </SliderContainer>
                                                <ValueContainer>{numPointsSliderValue}</ValueContainer>
                                            </SettingsTableCellFlexWrapper>
                                        </SettingsTableCell>
                                    </SettingsTableRow>
                                </SettingsTable>
                            </CenterSliderGridCell>
                            <Grid item xs={12} sm={12} md={4}>
                                <SettingsTable>
                                    <SettingsTableRow>
                                        <SettingsTableCell>
                                            Layer ID <b>BERT</b>:
                                        </SettingsTableCell>
                                    </SettingsTableRow>
                                    <SettingsTableRow>
                                        <SettingsTableCell>
                                            <SettingsTableCellFlexWrapper>
                                                <SliderContainer>
                                                    <Slider
                                                        min={0}
                                                        step={1}
                                                        max={11}
                                                        value={bertLayerID}
                                                        onChange={handleBertLayerIdChange}
                                                        onChangeCommitted={() => setBertLayerID(bertLayerID)}
                                                    />
                                                </SliderContainer>
                                                <ValueContainer>{bertLayerID + 1}</ValueContainer>
                                            </SettingsTableCellFlexWrapper>
                                        </SettingsTableCell>
                                    </SettingsTableRow>
                                </SettingsTable>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StyledCard>
                <CollapseButton $collapsed={collapsed} onClick={onCollapseExpandHandler} />
            </Container>
        </>
    );
};

export default ParameterPanel;

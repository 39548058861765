import { useState } from 'react';

export const useRevision = (): [number, () => void] => {
    const [revision, setRevision] = useState<number>(1);

    const updateRevision = () => {
        setRevision((prevState) => prevState + 1);
    };

    return [revision, updateRevision];
};
